import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Invoice from 'views/others/Request/Settlement/Invoice';
import AffilaiteInvoice from 'views/others/Request/Settlement/Invoice';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/Default')));

// pay in pay out pages

const PayIn = Loadable(lazy(() => import('views/pay-in-pay-out/PayIn')));
const Payout = Loadable(lazy(() => import('views/pay-in-pay-out/Payout')));

// other pages
const Reports = Loadable(lazy(() => import('views/others/Reports')));
const Settlement = Loadable(lazy(() => import('views/others/Request/Settlement')));

const childrenRoutes = [
    {
        path: '/dashboard/default',
        element: <DashboardDefault />
    },

    {
        path: '/payment/payin',
        element: <PayIn />
    },
    {
        path: '/payment/payout',
        element: <Payout />
    },
    {
        path: 'other/reports',
        element: <Reports />
    },
    {
        path: 'other/settlement-request',
        element: <Settlement />
    },
    {
        path: 'invoice/:settlementId/:merchantId',
        element: <Invoice />
    },
    {
        path: 'invoice/affilliate/:settlementId/:affiliateId',
        element: <AffilaiteInvoice />
    }
];

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: childrenRoutes
};

export default MainRoutes;
