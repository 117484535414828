// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconGitPullRequest, IconHelp, IconSitemap, IconReport, IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconReport,
    IconHelp,
    IconSitemap,
    IconSettings,
    IconGitPullRequest
};

// ==============================|| OTHER MENU ITEMS ||============================== //

export const MerchantOther = {
    id: 'others',
    title: <FormattedMessage id="Other" />,
    type: 'group',
    children: [
        {
            id: 'Reports',
            title: <FormattedMessage id="Reports" />,
            type: 'item',
            url: '/other/reports',
            icon: icons.IconReport,
            breadcrumbs: false
        },

        {
            id: 'settlement',
            title: <FormattedMessage id="Settlement" />,
            type: 'item',
            url: '/other/settlement-request',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
    ]
};
